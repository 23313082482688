<template>
    <el-form-item>
        <p v-if="clientNotePrivate" class="border rounded-md px-3 text-sm text-gray-600 bg-gray-100" v-html="clientNotePrivate" />
        <p v-else class="border rounded-md px-3 pt-1 text-sm text-gray-400 bg-gray-100 h-20">
            {{ $t('calendar.empty') }}
        </p>
    </el-form-item>
</template>

<script>
export default {
    props: {
        clientNotePrivateProp: {
            type:    String,
            default: '',
        },
    },
    computed: {
        clientNotePrivate() {
            return this.clientNotePrivateProp;
        },
    },
};
</script>
